import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import showdown from 'showdown'

const PageTemplate = ({
  heading,
  bodyMarkdown,
}) => {
  const converter = new showdown.Converter();
  const mdToHtml = (md) => {
    const ht = converter.makeHtml(md);
    return { __html: ht };
  };
  return (
    <section className="section">
      <Container>
        <Row>
          <style
            dangerouslySetInnerHTML={{
              __html: `img { width: 100% !important;}`,
            }} />
          <Col className="text-center" xl={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} xs="12">
            <h1>{heading}</h1>
          </Col>
          <Col xl={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} xs="12">
            <div dangerouslySetInnerHTML={mdToHtml(bodyMarkdown)} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

PageTemplate.propTypes = {
  heading: PropTypes.string,
};

export default PageTemplate;
