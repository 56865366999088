import React from "react";
import Layout from "../layout/Layout";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import PageTemplate from './page-template'

const Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <Helmet titleTemplate={`%s | ${frontmatter.title}`}>
        <title>{`${frontmatter.title}`}</title>
        <meta name="description" content={`${frontmatter.description}`} />
      </Helmet>
      <PageTemplate
        heading={frontmatter.heading}
        bodyMarkdown={data.markdownRemark.rawMarkdownBody}
      />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "page" } }
    ) {
      frontmatter {
        title
        heading
        description
      }
      rawMarkdownBody
    }
  }
`;
